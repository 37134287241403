import type { ImgSize } from '@/components/BaseImage.vue'

const breakpoints = {
  xs: 0,
  md: 768,
  lg: 1366
}

const targetScreenSizes = {
  xs: 360,
  md: 768,
  lg: 1366
}

type Breakpoint = keyof typeof breakpoints
export type SizeMap = Partial<Record<Breakpoint, ImgSize>>

export function getImageSizes(sizes: SizeMap) {
  return {
    imgSizes: Object.values(sizes),
    sizes: (Object.entries(sizes) as [Breakpoint, ImgSize][])
      .sort(([a], [b]) => breakpoints[b] - breakpoints[a])
      .map(([breakpoint, { w }]) => {
        const percentage = (w / targetScreenSizes[breakpoint]) * 100
        const imageSize = `${Math.round(percentage)}vw`
        const minScreenWidth = breakpoints[breakpoint]
        return minScreenWidth
          ? `(min-width: ${minScreenWidth}) ${imageSize}`
          : imageSize
      })
      .join(', ')
  }
}
